import React from "react";
import "./carrousel.css";
import {Container} from 'react-bootstrap';
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const Carrousel = (props) => { 
  
  const slides = [
    { titulo: '2015', description: 'Agosto: Premio TOYP a Jóvenes Sobresalientes por Junior Chamber International, categoría "Desarrollo Científico y Tecnológico".  Septiembre: Premio Raíces 2015 por LV DIEZ.'},
    { titulo: '2016', description: 'Octubre: Premio a la Mejor Startup Digital de Iberoamérica por Interlat. Febrero: Premio Joven Empresario 2015 Federación Económica de Mendoza y la Cámara de Jóvenes Empresarios de Mendoza.'},
    { titulo: '2019', description: 'Mayo: Campaña "La Empresa más querida por los mendocinos" declarada de interés municipal por el Gob. dela Ciudad de Mendoza. Junio: Certificación Empresa B.'},
    { titulo: '2020', description: 'Diciembre: Primera Certificación ISO 9001.'},
    { titulo: '2021', description: 'Septiembre: Certificación Carbono Neutral. Noviembre: Premios Jóvenes Mendocinos Destacados 2021 - Categoría Sustentabilidad - C.E.M. Diciembre: Primera rendición de cuentas públicas anual. Premio Ejecutivo del Año de A.E.M.'},
    { titulo: '2022', description: 'Octubre: Recertificación Empresa B - Sistema B. \nNoviembre: Premio “Dr. Gustavo Kent” en la categoría "Pequeñas Empresas de Innovación" de la Fundación UNCUYO.'},
  ];

  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 280;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 280;
  };
  
  return (
    
    <div id="main-slider-container">
      <Container>
        <div className='row'>
          <div className='col-md-12'>
            <h3 className='title-section'>Hitos de Quinto</h3>
          </div>
        </div>
      </Container>
      <div id="slider">
        {slides.map((slide, index) => {
          return (
            <div className="slider-card" key={index}>
              <h2 className="slider-card-titulo">{slide.titulo} </h2>
              <p className="slider-card-description">{slide.description}</p>
            </div>
          );
        })}
      </div>
      <div className="cont-btn">
        <MdChevronLeft
          size={90}
          className="slider-icon left"
          onClick={slideRight}
        />
        <MdChevronRight
          size={90}
          className="slider-icon right"
          onClick={slideLeft}
        />
      </div>
    </div>
  );
};

export default Carrousel;

