/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {Container} from 'react-bootstrap';
import './semillero.css';
import genb from '../../assets/imagenes/logo-genb.png';

function Genb() {
  return (
    <Container>
      <div id="Gen-B" className='row genb-section'>
        <div className='col-md-12'>
          <h3 className='title-section-2'>Gen-B</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <p className='subtext-section'>GEN B es una consultora en Economía, que acompaña a las 
          organizaciones a medir su impacto e implementar estrategias de sostenibilidad para optimizar sus 
          procesos, generando impacto social y ambiental positivo, a través de Certificaciones de Huella de 
          Carbono, Sistema B y desarrollo financiero para modelo de Negocios de impacto. El marco de referencia 
          sigue siendo, hasta 2030, el Acuerdo de París y sus 17 ODS. En esta hoja de ruta, las empresas tienen 
          un rol protagónico: reconvertir sus modelos de negocios para estar a la altura del nuevo paradigma 
          mundial: sostenible, resiliente, y con impacto positivo.</p>
        </div>
        <div className='col-md-6'>
          <img className='img-genb img-fluid' src={genb} alt="GenB" />
          <p className='parrafoRecursos2'>Para conocer más sobre GEN-B visita el sitio</p> 
          <a href='https://genbeconomia.com/' target="_blank" rel="noopener noreferrer" className='contRecursosBtn'>Ir a GEN-B</a>
        </div>
      </div>
    </Container>
  )
}

export default Genb;
