/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Container } from "react-bootstrap";
import "./sections.css";
import rectangle from '../../assets/imagenes/Rectangle 135.png';

function SectionC() {
  return (
    <Container>
      <div id="Quienessomos" className="row section-nosotros">
        <div className="col-md-9">
          <h3 className="title-section">Nosotros</h3>
          <p className="subtext-section">Quinto Impacto, nace en el año 2017 con el objetivo de generar 
          desarrollos tecnológicos de impacto alineados a los Objetivos de Desarrollo Sostenible de la ONU. 
          Estos desarrollos están orientados para empresas y emprendedores tanto locales como nacionales e 
          internacionales. Guiados por nuestro propósito, y en la búsqueda de hacer del mundo un lugar mejor, 
          elegimos ser una Empresa B y es por eso que consideramos imprescindible generar relaciones a largo 
          plazo, soluciones pensadas en conjunto y agregar valor en las cadenas de producción, siempre generado 
          triple impacto positivo. En este camino de crear impacto social, ambiental y económico, nos planteamos 
          un objetivo que para nosotros es fundamental: trabajar constantemente enfocados en la capacitación e 
          inserción laboral de talentos, brindando oportunidades a diferentes grupos sociales para acceder al 
          mercado tecnológico.</p>
        </div>
        <div className='col-md-3'>
        <img className='img-fluid' id='margin' src={rectangle} alt="Quintologo"/>
        </div>
      </div>
    </Container>
  );
}

export default SectionC;
