import React from 'react';
import {Container} from 'react-bootstrap';
import './semillero.css';
import sumate from '../../assets/imagenes/sumate.gif';

function TrabajaConNosotros() {
    return (
      <Container>
        <div className='row semillero-section'>
          <div className='col-md-12'>
            <div id="Nosotros" className='boxRecursos'>
              <div class="imgRecursos">
                <img alt="Sumate" src={sumate} className='img-fluid'/>
                <span className='visibility'>Illustration by <a href="https://icons8.com/illustrations/author/zD2oqC8lLBBA" rel="noopener noreferrer">Icons 8</a> from <a href="https://icons8.com/illustrations" rel="noopener noreferrer">Ouch!</a></span>  
              </div>
              <div class="contRecursos">
                <h3>Sumate a nuestro equipo</h3>
                <p>Completá el formulario </p>
                <a href="https://forms.gle/5JRee8LHjtZPT3Ss8" target="_blank" rel="noopener noreferrer">Ir al formulario</a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
  
  export default TrabajaConNosotros;