import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './home.css';
import Navbar from '../../components/navbar/Navbar';
import SectionA from '../../components/sections/SectionA';
import SectionB from '../../components/sections/SectionB';
import SectionC from '../../components/sections/SectionC';
import Clientes from '../../components/nuestros-clientes/Clientes';
import Misionvision from '../../components/mision-vision/Misionvision';
import Carrousel from '../../components/carrousel/Carrousel';
import Certificado from '../../components/certificado/Certificado';
import Equipo from '../../components/equipo-directivo/Equipo';
import Semillero from '../../components/semillero-genb/Semillero';
import Genb from '../../components/semillero-genb/Genb';
import TrabajaConNosotros from '../../components/semillero-genb/TrabajaConNosotros';
import Footer from '../../components/footer/Footer';

const Home = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <SectionA />
      <SectionB />
      <Misionvision />
      <SectionC />
      <Carrousel />
      <Certificado />
      <Equipo />
      <Clientes />
      <Semillero />
      <Genb />
      <TrabajaConNosotros />
      <Footer />
    </>
  )
};
export default Home;
