/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {Container} from 'react-bootstrap';
import './sections.css';
import queHacemos from '../../assets/imagenes/queHacemos.gif';

function SectionB() {
  return (
    <Container>
      <div id="Quehacemos" className='row section-desarrollos'>
        <h3 className='title-section'>¿Qué hacemos?</h3>
      </div>
      <div className='row'>    
        <div className='col-md-9'>
          <p className='subtext-section text-right'>Realizamos desarrollos tecnológicos de impacto. Cada desafío lo 
          abordamos desde la innovación, con equipos multidisciplinarios en constante crecimiento y formación 
          en diferentes herramientas y tecnologías, que aportan su mirada a los procesos de co-creación con 
          nuestros clientes. Acompañamos Procesos y Proyectos aportando dinamismo, innovación y nuevas formas 
          de trabajo. Acercamos metodologías ágiles y herramientas prácticas fomentando el potencial 
          intraemprendedor, generando el mayor impacto posible. Ponemos nuestro foco en generar cambios 
          positivos a partir de las nuevas tecnologías, agregando valor a la cadena de producción de quienes 
          nos eligen y priorizando las relaciones a largo plazo, entendiendo el poder y la responsabilidad de 
          crear y generar nuevos desarrollos.</p>
        </div>
        <div className='col-md-3 order-md-first img-desarrollo'>
          <img className='img-fluid' src={queHacemos} alt="Que hacemos"/>
          <span className='visibility'>Illustration by <a href="https://icons8.com/illustrations/author/zD2oqC8lLBBA" rel="noopener noreferrer">Icons 8</a> from <a href="https://icons8.com/illustrations" rel="noopener noreferrer">Ouch!</a></span>
        </div>
      </div>
    </Container>
  )
}

export default SectionB;
