import React from 'react';
import {Container} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import './equipo.css';
import seba from '../../assets/imagenes/Seba.jpg';
import rafa from '../../assets/imagenes/Rafa.jpg';
import pedro from '../../assets/imagenes/Pedro.jpg';
import caro from '../../assets/imagenes/CaroD.jpg';
import dani from '../../assets/imagenes/DaniM.jpg';
import fer from '../../assets/imagenes/FerB.jpg';
import ferF from '../../assets/imagenes/FerF.jpg';
import gabiM from '../../assets/imagenes/GabiM.jpg';
import gime from '../../assets/imagenes/Gimena.jpg';
import jesu from '../../assets/imagenes/JesusR.jpg';
import mati from '../../assets/imagenes/MatiasF.jpg';
import nicoV from '../../assets/imagenes/NicoV.jpg';
import pabloM from '../../assets/imagenes/PabloM.jpg';
import pauH from '../../assets/imagenes/PaulaH.jpg';
import pauM from '../../assets/imagenes/PaulaM.jpg';
import vane from '../../assets/imagenes/Vane.jpg';

function Equipo() {
  return (
    <Container className='equipo'>
      <div className='row section-equipo'>
        <div className='col-md-12'>
          <h3 className='title-section'>Equipo Directivo</h3>
        </div>
        <div className='col-md-10 offset-md-1 cards-equipo'>
          <Card className='card'>
            <Card.Img variant="top" src={seba} />
            <Card.Body>
              <Card.Title>Sebastian Arbona</Card.Title>
              <Card.Text>CTO - Ingeniero en Sistemas</Card.Text> 
              <Card.Text>Máster en Big Data e Innovación Tecnológica</Card.Text>
            </Card.Body>
          </Card>

          <Card className='card'>
            <Card.Img variant="top" src={rafa} />
            <Card.Body>
              <Card.Title>Rafael Kemelmajer</Card.Title>
              <Card.Text>CEO - Economista Master en Digital Business Management</Card.Text>
            </Card.Body>
          </Card>

          <Card className='card'>
            <Card.Img variant="top" src={pedro} />
            <Card.Body>
              <Card.Title>Pedro Tarak</Card.Title>
              <Card.Text>Consejero Internacional de Quinto Impacto</Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className='row section-equipo'>
        <div className='col-md-12'>
          <h3 className='title-section'>Encargados de Área</h3>
        </div>
        <div className='col-md-10 offset-md-1'>
          <div class="contCEA cards-equipoAreas">
            <Card className='card2'>
              <Card.Img variant="top" src={gime} />
              <Card.Body>
                <Card.Title>Gimena Cardozo</Card.Title>
                <Card.Text>Líder de Administración</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={ferF} />
              <Card.Body>
                <Card.Title>Fernando Fretes</Card.Title>
                <Card.Text>Líder de Infraestructura</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={jesu} />
              <Card.Body>
                <Card.Title>Jesus Rodríguez</Card.Title>
                <Card.Text>Líder Back-End</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={pauH} />
              <Card.Body>
                <Card.Title>Paula Hauscarriague</Card.Title>
                <Card.Text>Líder UX</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={fer} />
              <Card.Body>
                <Card.Title>Fernando Boschi</Card.Title>
                <Card.Text>Líder Front-End</Card.Text>            
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={caro} />
              <Card.Body>
                <Card.Title>Carolina Dottori</Card.Title>
                <Card.Text>Líder de Innovación</Card.Text> 
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={pabloM} />
              <Card.Body>
                <Card.Title>Pablo Muraciole</Card.Title>
                <Card.Text>Líder PM</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={mati} />
              <Card.Body>
                <Card.Title>Matías Fader</Card.Title>
                <Card.Text>Líder de Calidad</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={nicoV} />
              <Card.Body>
                <Card.Title>Nicolas Voloschin</Card.Title>
                <Card.Text>Líder de Área Comercial</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={vane} />
              <Card.Body>
                <Card.Title>Vanesa Cenci Tagarot</Card.Title>
                <Card.Text>Líder de Comunicación</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={pauM} />
              <Card.Body>
                <Card.Title>Paula Martinez De Moraes</Card.Title>
                <Card.Text>Líder de Capital Humano</Card.Text>
              </Card.Body>
            </Card>
            <Card className='card2'>
              <Card.Img variant="top" src={dani} />
              <Card.Body>
                <Card.Title>Daniela Magallan</Card.Title>
                <Card.Text>Administración</Card.Text>
              </Card.Body>
            </Card>    
            <Card className='card2'>
              <Card.Img variant="top" src={gabiM} />
              <Card.Body>
                <Card.Title>Gabriel Mendiola</Card.Title>
                <Card.Text>DevOps</Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Equipo;