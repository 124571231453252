import React from "react";
import "./navbar.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logoquinto from "../../assets/iconos/logo-QI.png";
import dashboard from "../../assets/imagenes/dashboard-QI.gif";
import tripleimpacto from "../../assets/imagenes/tripleimpacto.svg";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { CgClose } from "react-icons/cg";

function NavbarExample() {
  return (
    <div className="fondo">
      <header id="Header">
        {['lg'].map((expand) => (
          <Navbar key={expand} expand={expand} className="navbar-dark">
            <Container>
              <div class="row">
                <div class="col-3">
                  <Navbar.Brand>
                    <Nav.Link href="/">
                      <img alt="Logo Quinto Impacto" src={Logoquinto} />
                    </Nav.Link>
                  </Navbar.Brand>
                </div>
                <div class="col-9 menu">
                  <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <CgClose/>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav>
                        <Nav.Link href="/#Quehacemos" className="linkMenu">¿Qué hacemos?</Nav.Link>
                        <Nav.Link href="/#Quienessomos" className="linkMenu">¿Quiénes somos?</Nav.Link>
                        <Nav.Link href="/#Semillero" className="linkMenu">Semillero</Nav.Link>
                        <Nav.Link href="/#Gen-B" className="linkMenu">Gen -B</Nav.Link>
                        <Nav.Link href="/#Nosotros" className="linkMenu">Unite</Nav.Link>
                        <Nav.Link href="#Contacto" className="btnMenu">Contacto</Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </div>
              </div>
            </Container>
          </Navbar>
        ))}
      </header>
      <div class="container">
        <div class="row alignCenter">
          <div class="col-sm-6">
            <h1 className="titulo">Desarrollamos <br></br>innovaciones tecnológicas <br></br>de 
              <span>
                <img src={tripleimpacto}  alt="Triple Impacto" className="img-fluid"/>
              </span>
            </h1>
          </div>
          <div class="col-sm-6 imgDashboard">
            <img src={dashboard}  alt="Imagen Portada" className="img-fluid"/>
            <span className='visibility'>Illustration by <a href="https://icons8.com/illustrations/author/zD2oqC8lLBBA" rel="noopener noreferrer">Icons 8</a> from <a href="https://icons8.com/illustrations" rel="noopener noreferrer">Ouch!</a></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavbarExample;
