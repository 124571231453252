import React from 'react'
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import './clientes.css';
import logosquinto01 from '../../assets/iconos/logos-quinto-01.png';
import logosquinto02 from '../../assets/iconos/logos-quinto-02.png';
import logosquinto03 from '../../assets/iconos/logos-quinto-03.png';
import logosquinto04 from '../../assets/iconos/logos-quinto-04.png';
import logosquinto05 from '../../assets/iconos/logos-quinto-05.png';
import logosquinto06 from '../../assets/iconos/logos-quinto-06.png';
import logosquinto07 from '../../assets/iconos/logos-quinto-07.png';
import logosquinto08 from '../../assets/iconos/logos-quinto-08.png';
import logosquinto09 from '../../assets/iconos/logos-quinto-09.png';
import logosquinto10 from '../../assets/iconos/logos-quinto-10.png';
import logosquinto11 from '../../assets/iconos/logos-quinto-11.png';
import logosquinto12 from '../../assets/iconos/logos-quinto-12.png';
import logosquinto13 from '../../assets/iconos/logos-quinto-13.png';
import logosquinto14 from '../../assets/iconos/logos-quinto-14.png';
import logosquinto15 from '../../assets/iconos/logos-quinto-15.png';
import logosquinto16 from '../../assets/iconos/logos-quinto-16.png';
import logosquinto17 from '../../assets/iconos/logos-quinto-17.png';
import logosquinto18 from '../../assets/iconos/logos-quinto-18.png';
import logosquinto19 from '../../assets/iconos/logos-quinto-19.png';
import logosquinto20 from '../../assets/iconos/logos-quinto-20.png';
import logosquinto21 from '../../assets/iconos/logos-quinto-21.png';
import logosquinto22 from '../../assets/iconos/logos-quinto-22.png';
import logosquinto23 from '../../assets/iconos/logos-quinto-23.png';
import logosquinto24 from '../../assets/iconos/logos-quinto-24.png';

function Clientes() {
  return (
    <Container className='clientes'>
      <div className='row section-clientes'>
        <div className='col-md-12'>
          <h2 className='web-title'>Nuestros Clientes</h2>
        </div>
        <div className='col-md-12 cards-clientes'>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto01} alt="Logo BYMA" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto02} alt="Logo Tecval" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto03} alt="Logo Caja de Valores" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto04} alt="Logo FTyC" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto05} alt="Logo Gira por las casas" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto06} alt="Logo Pizarra Blanca" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto07} alt="Logo Epiliquid" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto08} alt="Logo HEMA" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto09} alt="Logo GIRO" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto10} alt="Logo Sumatoria" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto11} alt="Logo Cárbula" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto12} alt="Logo Acrux" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto13} alt="Logo Bid" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto14} alt="Logo Yecas" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto15} alt="Logo La más querida" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto16} alt="Logo Caperuza" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto17} alt="Logo Mendoza Ciudad" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto18} alt="Logo Municipalidad de Luján de Cuyo" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto19} alt="Logo Gobierno de Mendoza" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto20} alt="Logo Entre Ríos" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto21} alt="Logo Adelia" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto22} alt="Logo Wipper" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto23} alt="Logo Oficios Club" />
          </Card>
          <Card className='card'>
            <Card.Img variant="top" src={logosquinto24} alt="Logo Coef" />
          </Card>
        </div>
      </div>
    </Container>
  )
}

export default Clientes;