import React from 'react';
import {Container} from 'react-bootstrap';
import './semillero.css';
import semillero from '../../assets/imagenes/semillero.png';

function Semillero() {
  return (
    <Container>
      <div id="Semillero" className='row semillero-section'>
        <div className='col-md-12'>
          <h3 className='title-section-2'>Semillero</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <p className='subtext-section'>A través del Semillero brindamos la oportunidad de que jóvenes de 
          diferentes contextos y que poseen conocimientos básicos en programación, obtengan su primera 
          experiencia laboral en un proyecto real de impacto y además, profundicen su formación por medio de 
          capacitaciones. Esta experiencia permite que adquieran las herramientas necesarias para insertarse 
          en el mercado laboral dentro del mundo IT. A través del programa, los jóvenes acceden a una serie de 
          cursos y prácticas encabezadas por expertos en las últimas tendencias y metodologías de la 
          programación, buscando así generar empoderamiento personal y colectivo, resaltando siempre la 
          necesidad y la importancia de trabajar en forma colaborativa.</p>          
        </div>
        <div className='col-md-6'>
          <img className='img-semillero img-fluid' src={semillero} alt="Semillero" />
          <p className='parrafoRecursos2'>Para ser parte del programa completá el formulario.</p> 
          <a href='https://docs.google.com/forms/d/e/1FAIpQLSclNycNn77_pNiv3ib5TxbSzRbseqi6LA_UkLPhWhivop9t8w/viewform' target="_blank" rel="noopener noreferrer" className='contRecursosBtn'>Quiero ser parte</a>
        </div>
      </div>
    </Container>
  )
}

export default Semillero;
