import React from 'react';
import {Container} from 'react-bootstrap';
import './sections.css';
import empresab from '../../assets/imagenes/empresab.png';
import arrowright from "../../assets/iconos/arrowright.svg";

function SectionA() {
  return (
    <Container>
      <div id="Quehacemos" className='row section-empresab'>
        <div className='col-md-12'>
          <h3 className='title-section'>Somos empresa B certificada</h3>
        </div>
      </div>
      <div className='row'>    
        <div className='col-md-9'>
          <p className='subtext-section'>Las empresas que nos certificamos como Empresa B no somos perfectas, 
          pero asumimos con mucha responsabilidad un compromiso de mejora continua y ponemos nuestro propósito 
          empresarial socioambiental en el centro del modelo de negocio. ¿Qué significa esto? Que medimos y 
          analizamos las cinco áreas más relevantes de la empresa: Gobierno, Trabajadores, Clientes, Comunidad
          y Medio Ambiente, permitiendo una revisión detallada de todas ellas, con el fin de ayudar a 
          identificar todos los posibles puntos de mejora y oportunidades para ser agentes de cambio en la 
          economía, protegiendo la misión y potenciando el triple impacto, buscando ser mejores para el mundo 
          y no solo la mejor empresa del mundo. Además, a través de distintos proyectos y alianzas, buscamos 
          vincular Universidades, Gobierno y Empresas para generar impacto positivo, caminando juntos hacia un 
          mundo más colaborativo y sostenible donde el desarrollo, la tecnología y las personas estén puestas 
          al servicio de los grandes desafíos que tiene hoy la humanidad.</p>
        </div>
        <div className='col-md-3'>
          <img className='img-empresab img-fluid' src={empresab} alt="Logo Empresa B"/>
        </div>
      </div>
      <div className='row'> 
        <div className='col-md-12'>
          <a href="https://www.sistemab.org/" className='btnSection' target="_blank" rel="noopener noreferrer">
            <img src={arrowright}  alt="Flecha conocer más" className="img-fluid"/>
            Conoce más
          </a>
        </div>
      </div>
    </Container>
  )
}

export default SectionA;
