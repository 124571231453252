import React from 'react';
import { Container } from 'react-bootstrap';
import './politicas.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import queHacemos from '../../assets/imagenes/queHacemos.gif';

const Politicas = () => {
  return (
    <>
      <Navbar />
      <Container className='clientes'>
        <div className='row'>
          <div className='col-md-9'>
            <h3 className='title-section'>Política de Calidad</h3>
            <p className='subtext-section'>En nuestra empresa, nos comprometemos a planificar y ejecutar nuestros proyectos
              de "Diseño y Desarrollo de software" siguiendo los requisitos de la norma ISO 9001:2015. Basamos nuestra política de
              calidad en los siguientes principios:</p>
            <ul>
              <li className='subtext-section'><b>Impacto Social Positivo:</b> Promovemos proyectos que generen un impacto social altamente positivo, fundamentado en
                nuestra conciencia ética y social. Nos enfocamos en la integración social y la generación de inclusión y diversidad
                de personas, en línea con los Objetivos de Desarrollo Sostenible de la ONU.</li>
              <li className='subtext-section'><b>Excelencia y Mejora Continua:</b> Buscamos la excelencia en nuestros procesos y servicios, favoreciendo la mejora
                continua y estableciendo objetivos de calidad que nos permitan mejorar continuamente la eficacia de nuestro sistema
                de gestión.</li>
              <li className='subtext-section'><b>Satisfacción del Cliente:</b> Priorizamos la satisfacción de nuestros clientes, enfocándonos en sus necesidades y
                expectativas. Nuestro compromiso es ofrecer soluciones tecnológicas que cumplan y superen sus expectativas.</li>
              <li className='subtext-section'><b>Innovación:</b> Utilizamos la innovación y la mejora continua como herramientas principales para lograr desarrollos
                tecnológicos acordes a los requerimientos de nuestros clientes y al contexto social y ambiental.</li>
              <li className='subtext-section'><b>Relaciones Sostenibles:</b> Fomentamos relaciones sostenibles con todas nuestras partes interesadas, asegurando que
                nuestras acciones sean buenas para el mundo y contribuyan a un desarrollo positivo y cuantificable.</li>
            </ul>
            <p>Con esta política de calidad, buscamos ser una fuente de inspiración para otras personas, comunidades y organizaciones,
              motivándolos a participar en acciones conscientes y responsables que beneficien al mundo a través de la tecnología de
              triple impacto.</p>
          </div>
          <div className='col-md-3 d-none d-md-block'>
            <img className='img-fluid imgPoliticas' src={queHacemos} alt="Que hacemos"/>
            <span className='visibility'>Illustration by <a href="https://icons8.com/illustrations/author/zD2oqC8lLBBA" rel="noopener noreferrer">Icons 8</a> from <a href="https://icons8.com/illustrations" rel="noopener noreferrer">Ouch!</a></span>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
};
export default Politicas;
