import React from 'react';
import { Container } from 'react-bootstrap';
import './mision.css';

function Misionvision() {
  return (
    <div className='misionvision'>
      <Container >
        <div className='row'>
          <div className='col-md-12 dfVM'>
            <div className='boxVM'>
              <h3>Misión</h3>
              <p>Desarrollamos innovaciones tecnológicas de triple impacto desde la integración social, 
                involucrándonos en la generación de inclusión y diversidad de personas, siempre alineados a 
                los Objetivos de Desarrollo Sostenible de la ONU.</p>
            </div>
            <div className='boxVM'>
              <h3>Visión</h3>
              <p>Queremos ser fuente de inspiración para otras personas, comunidades y organizaciones, 
                motivándolos a sumarse en acciones que sean buenas para el mundo, con conciencia y 
                responsabilidad a través de desarrollos tecnológicos de triple impacto, de forma positiva y 
                cuantificable.</p>
            </div>
          </div>
        </div>
      </Container>
    </div>  
  )
}

export default Misionvision;
