import React from 'react';
import {Container} from 'react-bootstrap';
import './certificado.css';
import Iso from "../../assets/imagenes/quinto_impacto_certificados_iso.svg";
import CertificadoImg from "../../assets/documentos/QUINTO_IMPACTO_9001_IRAM.jpg";
import { BiXCircle } from "react-icons/bi";

function Certificado() {
  return (
    <Container className='certificado' id="certificado">
      <div className='row'>
        <div className='col-md-12'>
          <div className='boxRecursos'>
            <div class="imgRecursos">
              <img alt="Certificado ISO 9001" src={Iso} className='img-fluid'/>
            </div>
            <div class="contRecursos">
              <h3>Certificación ISO 9001</h3>
              <p>La Certificación de Calidad IRAM 9001 es un reconocimiento de  nuestro compromiso con la excelencia en la calidad. 
                Significa que nuestros procesos están alineados con los estándares internacionales más rigurosos, asegurando 
                productos/servicios de primer nivel para nuestros clientes. ¡Gracias por ser parte de este logro!</p>
              <a href='#modal_certificado'>Ver Certificado</a>
              
            </div>
          </div>
        </div>
      </div>
      <div id="modal_certificado" class="modal_mask">
        <div class="box-modal">
          <a href="#certificado"><BiXCircle /></a>
          <img alt="Certificado ISO 9001" src={CertificadoImg} className='img-fluid'/>
        </div>
      </div> 
    </Container>
  )
}

export default Certificado;